import {Services} from '../../../common/ts/core/services/Services';
import Company from '../../../common/ts/core/services/Company';
import CoreIntegration from '../../../common/integrations/ts/Core';
import * as Sentry from '@sentry/browser';
import {Analytics} from '../../../common/ts/core/services/Analytics';

class NameTagsCoreIntegration extends CoreIntegration {
    override setupSentry() {
        let environment = 'production';
        if (window.location.origin.indexOf('192.168') != -1 || window.location.origin.indexOf('localhost') != -1) {
            environment = 'development';
        }
        else if (window.location.origin.indexOf('staging') != -1) {
            environment = 'staging';
        }

        // Dont log with development environment
        if (environment != 'development') {
            Sentry.init({
                dsn: "https://cd8c58d1869c4aa5aa2ed56cc13a1845@o116203.ingest.sentry.io/4504934732726272",

                // To set your release version
                release: "nametags-frontend@1.83.0",
                integrations: [Sentry.browserTracingIntegration(),],

                // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
                // Until we upgrade our account and want to use performance sampling this is being disabled
                tracesSampleRate: 0,
                environment: environment,
            });
        }
    }

    override configureServices() {
        Services.get<Company>('Company').setup(
            'Custom Name Tags',
            '844-647-2730',
            'sales@customnametags.com',
            '2146 NE 4th Street \n ' +
                    'Suite 100 \n ' +
                    'Bend, OR 97701',
            'https://www.customnametags.com/static/img/logo-large.png',
            'CustomNameTags',
            'customnametags.com',
            null,
            null,
            null,
            'nametags'
        );

        Services.get<Analytics>('Analytics').trackPage(window.location.pathname);

        super.configureServices();
    }
}
const core = new NameTagsCoreIntegration();
